import React from 'react'
import Typography from 'antd/lib/typography'
import moment from 'moment'
import Tooltip from 'antd/lib/tooltip'
import { getStatus } from 'utils/getStatus'
import MasterRule from 'components/MasterRule/MasterRule'
import { AiFillStar, AiFillFlag, AiFillDollarCircle } from 'react-icons/ai'
import ExpirationDate from 'components/ExpirationDate'
import outdated from 'utils/outDateRule'
import { StyledBadge } from './styles'
import actionsDropdown from './actionsDropdown'

const { Text } = Typography

const columns = (dispatch, openDrawer) => {
  return [
    {
      title: '',
      dataIndex: 'crossRule',
      key: 'crossRule',
      render: (crossRule) => {
        return crossRule ? <AiFillStar data-testid="start-id" fontSize="12px" style={{ color: '#F7981C', margin: '0px 4px' }} /> : null
      },
      width: 20,
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'isPremium',
      render: (isPremium) => {
        return isPremium ? <AiFillDollarCircle fontSize="18px" style={{ color: '#00DF00', margin: '0px 4px' }} /> : null
      },
      width: 20,
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'flag',
      render: (flag) => {
        return flag ? <AiFillFlag fontSize="15px" style={{ color: '#FF1005', margin: '0px 4px' }} /> : null
      },
      width: 20,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Name</div>,
      dataIndex: 'name',
      key: ['rule', 'name'],
      render: (name, rule) => {
        return (
          <MasterRule name={name} lastUseDate={rule?.lastUsedDate ?? ''} masterRule={rule.crossRule ?? false} />
        )
      },
      width: 120,
    },
    {
      title: '',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: (expirationDate) => {
        return expirationDate && moment(expirationDate).isValid() ? (
          <ExpirationDate
            expirationDate={moment(expirationDate).format('YYYY-MM-DD')}
            isExpirated={outdated(expirationDate)}
          />
        ) : null
      },
      width: 30,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Between</div>,
      dataIndex: ['rule','minLeadTime'],
      key: 'minLeadTime',
      render: (minLeadTime, rule) => {
        return (
          <Text style={{ fontSize: '16px', display: 'flex' }}>
            {minLeadTime ?? ""}
            <span style={{ margin: '0px', padding: '0px', color: '#3594FF', fontWeight: '600' }}> & </span>
            {rule?.rule?.maxLeadTime ?? ""}
          </Text>
        )
      },
      width: 100,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Percentage</div>,
      dataIndex: ['rule', 'margin'],
      key: 'margin',
      render: (margin) => {
        return <Text style={{ fontSize: '16px', display: 'flex' }}>{margin} %</Text>
      },
      width: 90,
      ellipsis: true,
      editable: true
    },
    {
      title: <div style={{ fontSize: '16px' }}>Flat</div>,
      dataIndex: ['rule', 'flat'],
      key: 'flat',
      render: (flat) => {
        return <Text style={{ fontSize: '16px', display: 'flex' }}>{flat}</Text>
      },
      width: 90,
      ellipsis: true,
      editable: true
    },
    {
      title: <div style={{ fontSize: '16px' }}># Multi-step rules</div>,
      dataIndex: 'multiStepsRuleInfo',
      key: 'multiStepsRuleInfo',
      colSpan: 1,
      render: (multiStepsRuleInfo) => {
        multiStepsRuleInfo = multiStepsRuleInfo ?? { quantity: 0, multistepNames: [] }
        return (
          <Tooltip
            placement="bottom"
            title={
              multiStepsRuleInfo.multistepNames.length > 0
                ? multiStepsRuleInfo.multistepNames.map((v, index) => <div key={index}>{v}</div>)
                : null
            }
            color="#3594FF"
          >
            <Text underline style={{ color: '#3594FF', cursor: 'pointer', fontSize: '16px', display: 'flex' }}>
              {multiStepsRuleInfo.quantity}
            </Text>
          </Tooltip>
        )
      },
      width: 110,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}># RulesSets</div>,
      dataIndex: 'rulesetsInfo',
      key: 'rulesetsInfo',
      colSpan: 1,
      render: (rulesetsInfo) => {
        rulesetsInfo = rulesetsInfo ?? { quantity: 0, ruleSetsNames: [] }
        return (
          <Tooltip
            placement="bottom"
            title={
              rulesetsInfo.ruleSetsNames.length > 0
                ? rulesetsInfo.ruleSetsNames.map((v, index) => <div key={index}>{v}</div>)
                : null
            }
            color="#3594FF"
          >
            <Text underline style={{ color: '#3594FF', cursor: 'pointer', fontSize: '16px', display: 'flex' }}>
              {rulesetsInfo.quantity}
            </Text>
          </Tooltip>
        )
      },
      width: 110,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Status</div>,
      dataIndex: 'active',
      key: ['rate', 'active'],
      render: (active, rate) => {
        return (
          <div style={{ display: 'flex' }}>
            <StyledBadge variant={getStatus(rate.status, active)} />
          </div>
        )
      },
      width: 70,
    },
    {
      ...actionsDropdown(dispatch, openDrawer),
    },
  ]
}

export default columns
