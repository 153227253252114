import React from 'react'
import Tooltip from 'antd/lib/tooltip'
import Typography from 'antd/lib/typography'
import moment from 'moment'
import { getStatus } from 'utils/getStatus'
import MasterRule from 'components/MasterRule/MasterRule'
import { AiFillStar, AiFillFlag, AiFillDollarCircle } from 'react-icons/ai'
import ExpirationDate from 'components/ExpirationDate'
import outdated from 'utils/outDateRule'
import { StyledBadge, StyledText } from './styles'
import actionsDropdown from './actionsDropdown'

const { Text } = Typography

const columns = (dispatch, shippingType, geographicType, openDrawer,enableEdit=true) => {
  return [
    {
      title: '',
      dataIndex: 'crossRule',
      render: (crossRule) => {
        return crossRule ? <AiFillStar fontSize="15px" style={{ color: '#F7981C', margin: '0px 4px' }} /> : null
      },
      width: 20,
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'isPremium',
      render: (isPremium) => {
        return isPremium ? <AiFillDollarCircle fontSize="18px" style={{ color: '#00DF00', margin: '0px 4px' }} /> : null
      },
      width: 20,
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'flag',
      render: (flag) => {
        return flag ? <AiFillFlag fontSize="15px" style={{ color: '#FF1005', margin: '0px 4px' }} /> : null
      },
      width: 20,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Name</div>,
      dataIndex: 'name',
      key: ['rate', 'name'],
      render: (name, rate) => {
        return <MasterRule name={name} lastUseDate={rate?.lastUsedDate ?? ''} />
      },
      width: 110,
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'expirationDate',
      render: (expirationDate) => {
        return expirationDate && moment(expirationDate).isValid() ? (
          <ExpirationDate
            expirationDate={moment(expirationDate).format('YYYY-MM-DD')}
            isExpirated={outdated(expirationDate)}
          />
        ) : null
      },
      width: 30,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Type</div>,
      dataIndex: 'geographic',
      key: 'geographic',
      colSpan: 1,
      render: () => {
        return (
          <StyledText style={{ fontSize: '16px', display: 'flex' }}>
            {shippingType === 'outState' || shippingType === 'outZip' ? 'For Outbound' : 'For Inbound'}
          </StyledText>
        )
      },
      width: 70,
      ellipsis: true,
    },
    {
      title:
        geographicType === 'state' ? (
          <div style={{ fontSize: '16px' }}> State </div>
        ) : (
          <div style={{ fontSize: '16px' }}> 3 Digit Zip</div>
        ),
      dataIndex: ['rule', 'guide'],
      key: 'guide',
      colSpan: 1,
      render: (guide) => {
        return <StyledText style={{ fontSize: '16px', display: 'flex' }}>{guide}</StyledText>
      },
      width: 105,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Percentage</div>,
      dataIndex: ['rule', 'margin'],
      key: 'margin',
      render: (margin) => {
        return <StyledText style={{ fontSize: '16px', display: 'flex' }}>{margin} %</StyledText>
      },
      width: 75,
      ellipsis: true,
      editable: enableEdit
    },
    {
      title: <div style={{ fontSize: '16px' }}>Flat</div>,
      dataIndex: ['rule', 'flat'],
      key: 'flat',
      render: (flat) => {
        return <StyledText style={{ fontSize: '16px', display: 'flex' }}>{flat}</StyledText>
      },
      width: 75,
      ellipsis: true,
      editable: enableEdit
    },
    // {
    //   title: <div style={{ fontSize: '16px' }}>Overlapping</div>,
    //   dataIndex: 'overlappingGeographicsRules',
    //   key: 'overlappingGeographicsRules',
    //   render: (over) => {
    //     return ( 
    //       <Tooltip
    //         placement="bottom"
    //         title={
    //           over && Array.isArray(over)
    //             ? over.map((v, index) => <div key={`${index + v.name}`}>{v.name}</div>)
    //             : null
    //         }
    //         color="#3594FF"
    //       >
    //         <Text underline style={{ color: '#3594FF', cursor: 'pointer', fontSize: '16px', display: 'flex' }}>
    //           {over && Array.isArray(over) ? over.length : ''}
    //         </Text>
    //       </Tooltip>
    //     )
    //   },
    //   width: 75,
    //   ellipsis: true,
    // },
    {
      title: <div style={{ fontSize: '16px' }}> # Multi-step rules</div>,
      dataIndex: 'multiStepsRuleInfo',
      key: 'multiStepsRuleInfo',
      colSpan: 1,
      render: (multiStepsRuleInfo) => {
        multiStepsRuleInfo = multiStepsRuleInfo ?? { quantity: 0, multistepNames: [] }
        return (
          <Tooltip
            placement="bottom"
            title={
              multiStepsRuleInfo.multistepNames.length > 0
                ? multiStepsRuleInfo.multistepNames.map((v, index) => <div key={index}>{v}</div>)
                : null
            }
            color="#3594FF"
          >
            <Text underline style={{ color: '#3594FF', cursor: 'pointer', fontSize: '16px', display: 'flex' }}>
              {multiStepsRuleInfo.quantity}
            </Text>
          </Tooltip>
        )
      },
      width: 90,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}># RulesSets</div>,
      dataIndex: 'rulesetsInfo',
      key: 'rulesetsInfo',
      colSpan: 1,
      render: (rulesetsInfo) => {
        rulesetsInfo = rulesetsInfo ?? { quantity: 0, ruleSetsNames: [] }
        return (
          <Tooltip
            placement="bottom"
            title={
              rulesetsInfo.ruleSetsNames.length > 0
                ? rulesetsInfo.ruleSetsNames.map((v, index) => <div key={index}>{v}</div>)
                : null
            }
            color="#3594FF"
          >
            <Text underline style={{ color: '#3594FF', cursor: 'pointer', fontSize: '16px', display: 'flex' }}>
              {rulesetsInfo.quantity}
            </Text>
          </Tooltip>
        )
      },
      width: 90,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}> Status</div>,
      dataIndex: 'active',
      key: ['rate', 'active'],
      render: (active, rate) => {
        return (
          <div style={{ display: 'flex' }}>
            <StyledBadge variant={getStatus(rate.status, active)} />
          </div>
        )
      },
      width: 75,
    },
    {
      ...actionsDropdown(dispatch, openDrawer),
    },
  ]
}

export default columns
